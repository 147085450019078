/**
 * Производит редирект всех страниц (кроме главной) без trailingSlash на страницы с trailingSlash с 301 статусом редиректа.
 * Важно для SEO.
 * */
export default defineNuxtRouteMiddleware((to) => {
  if (to.name && to.path !== '/' && !to.path.endsWith('/')) {
    const { path, query, hash } = to

    return navigateTo({ path: path + '/', query, hash }, { redirectCode: 301 })
  }
})
