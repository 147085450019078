import validate from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45old_45page_45check_45global from "/home/app/pd-awards-frontend/middleware/01.old-page-check.global.ts";
import _02_45initialize_45global from "/home/app/pd-awards-frontend/middleware/02.initialize.global.ts";
import redirect_45to_45trailing_45slash_45global from "/home/app/pd-awards-frontend/middleware/redirectToTrailingSlash.global.ts";
import manifest_45route_45rule from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45old_45page_45check_45global,
  _02_45initialize_45global,
  redirect_45to_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "fetch-region": () => import("/home/app/pd-awards-frontend/middleware/fetch-region.ts"),
  "year-check": () => import("/home/app/pd-awards-frontend/middleware/year-check.ts")
}