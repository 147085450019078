const isModernPath = (path: string) => {
  const pageRegExp = /(\/(awards|grand-prix)\/[0-9]{4}\/)/g // если есть приставка /(awards|grand-prix)/xxxx/

  return pageRegExp.test(path)
}

const isPathHaveCityAndCategory = (path: string) => {
  const categoryRegExp = /(\/[a-z-]+\/(doctors|lpus))/ // если есть определение города и категории // /REGION_TRANSLITE/(doctors/lpus)/

  return categoryRegExp.test(path)
}

export default defineNuxtRouteMiddleware((to) => {
  if (to.name || isModernPath(to.path)) {
    return
  }

  if (isPathHaveCityAndCategory(to.path)) {
    return navigateTo({ path: '/awards/2020' + to.path, hash: to.hash || '' })
  }

  if (to.path === '/certificates/') {
    return
  }

  return navigateTo({ path: '/' })
})
