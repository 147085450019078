// https://stackoverflow.com/questions/69902990/page-not-scrolling-to-top-when-route-changes-in-nuxt-js/73893669#73893669
import type { RouterOptions } from '@nuxt/schema'
import type { RouterScrollBehavior } from 'vue-router'

// NOTE: Позволяет применять скролл к странице после ее полной загрузки
// https://stackoverflow.com/questions/74659559/nuxt-js-3-scroll-behavior-after-page-load-completed
const scrollAfterPageLoaded = (scrollOptions: Awaited<ReturnType<RouterScrollBehavior>>) => {
  return new Promise((resolve) => {
    useNuxtApp().hooks.hookOnce('page:finish', async () => {
      await nextTick()

      resolve(scrollOptions)
    })
  })
}

export default <RouterOptions> {
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.params._disableAutoScrollToHash !== 'true') {
      if (/(doctors)|(lpus)/.test(to.name as string)) {
        return false
      }

      if (to.name === from.name) {
        return {
          el: to.hash,
          behavior: 'smooth',
        }
      }

      return scrollAfterPageLoaded({
        el: to.hash,
        behavior: 'smooth',
      })
    }

    if (to.name === from.name) {
      return false
    }

    if (savedPosition) {
      return savedPosition
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 10)
    })
  },
}
