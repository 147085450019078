import { default as doctorsstW8YOjXSTMeta } from "/home/app/pd-awards-frontend/pages/awards/[year]/[city]/doctors.vue?macro=true";
import { default as lpus6jOTCzYe6zMeta } from "/home/app/pd-awards-frontend/pages/awards/[year]/[city]/lpus.vue?macro=true";
import { default as _91city_93Fx2DAr7yjpMeta } from "/home/app/pd-awards-frontend/pages/awards/[year]/[city].vue?macro=true";
import { default as indexGsbyZL32P6Meta } from "/home/app/pd-awards-frontend/pages/awards/[year]/index.vue?macro=true";
import { default as indexGEPuVRls0YMeta } from "/home/app/pd-awards-frontend/pages/awards/index.vue?macro=true";
import { default as doctorsKcIUsAUjErMeta } from "/home/app/pd-awards-frontend/pages/grand-prix/[year]/doctors.vue?macro=true";
import { default as lpusItZA1dVmN4Meta } from "/home/app/pd-awards-frontend/pages/grand-prix/[year]/lpus.vue?macro=true";
import { default as _91year_93AcWjZWehxtMeta } from "/home/app/pd-awards-frontend/pages/grand-prix/[year].vue?macro=true";
import { default as indexIKNOE5vorCMeta } from "/home/app/pd-awards-frontend/pages/grand-prix/index.vue?macro=true";
import { default as index6xS8SmB7gEMeta } from "/home/app/pd-awards-frontend/pages/index.vue?macro=true";
export default [
  {
    name: _91city_93Fx2DAr7yjpMeta?.name ?? "awards-year-city",
    path: _91city_93Fx2DAr7yjpMeta?.path ?? "/awards/:year()/:city()",
    meta: _91city_93Fx2DAr7yjpMeta || {},
    alias: _91city_93Fx2DAr7yjpMeta?.alias || [],
    redirect: _91city_93Fx2DAr7yjpMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/awards/[year]/[city].vue").then(m => m.default || m),
    children: [
  {
    name: doctorsstW8YOjXSTMeta?.name ?? "awards-year-city-doctors",
    path: doctorsstW8YOjXSTMeta?.path ?? "doctors",
    meta: doctorsstW8YOjXSTMeta || {},
    alias: doctorsstW8YOjXSTMeta?.alias || [],
    redirect: doctorsstW8YOjXSTMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/awards/[year]/[city]/doctors.vue").then(m => m.default || m)
  },
  {
    name: lpus6jOTCzYe6zMeta?.name ?? "awards-year-city-lpus",
    path: lpus6jOTCzYe6zMeta?.path ?? "lpus",
    meta: lpus6jOTCzYe6zMeta || {},
    alias: lpus6jOTCzYe6zMeta?.alias || [],
    redirect: lpus6jOTCzYe6zMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/awards/[year]/[city]/lpus.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexGsbyZL32P6Meta?.name ?? "awards-year",
    path: indexGsbyZL32P6Meta?.path ?? "/awards/:year()",
    meta: indexGsbyZL32P6Meta || {},
    alias: indexGsbyZL32P6Meta?.alias || [],
    redirect: indexGsbyZL32P6Meta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/awards/[year]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGEPuVRls0YMeta?.name ?? "awards",
    path: indexGEPuVRls0YMeta?.path ?? "/awards",
    meta: indexGEPuVRls0YMeta || {},
    alias: indexGEPuVRls0YMeta?.alias || [],
    redirect: indexGEPuVRls0YMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/awards/index.vue").then(m => m.default || m)
  },
  {
    name: _91year_93AcWjZWehxtMeta?.name ?? "grand-prix-year",
    path: _91year_93AcWjZWehxtMeta?.path ?? "/grand-prix/:year()",
    meta: _91year_93AcWjZWehxtMeta || {},
    alias: _91year_93AcWjZWehxtMeta?.alias || [],
    redirect: _91year_93AcWjZWehxtMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/grand-prix/[year].vue").then(m => m.default || m),
    children: [
  {
    name: doctorsKcIUsAUjErMeta?.name ?? "grand-prix-year-doctors",
    path: doctorsKcIUsAUjErMeta?.path ?? "doctors",
    meta: doctorsKcIUsAUjErMeta || {},
    alias: doctorsKcIUsAUjErMeta?.alias || [],
    redirect: doctorsKcIUsAUjErMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/grand-prix/[year]/doctors.vue").then(m => m.default || m)
  },
  {
    name: lpusItZA1dVmN4Meta?.name ?? "grand-prix-year-lpus",
    path: lpusItZA1dVmN4Meta?.path ?? "lpus",
    meta: lpusItZA1dVmN4Meta || {},
    alias: lpusItZA1dVmN4Meta?.alias || [],
    redirect: lpusItZA1dVmN4Meta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/grand-prix/[year]/lpus.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexIKNOE5vorCMeta?.name ?? "grand-prix",
    path: indexIKNOE5vorCMeta?.path ?? "/grand-prix",
    meta: indexIKNOE5vorCMeta || {},
    alias: indexIKNOE5vorCMeta?.alias || [],
    redirect: indexIKNOE5vorCMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/grand-prix/index.vue").then(m => m.default || m)
  },
  {
    name: index6xS8SmB7gEMeta?.name ?? "index",
    path: index6xS8SmB7gEMeta?.path ?? "/",
    meta: index6xS8SmB7gEMeta || {},
    alias: index6xS8SmB7gEMeta?.alias || [],
    redirect: index6xS8SmB7gEMeta?.redirect,
    component: () => import("/home/app/pd-awards-frontend/pages/index.vue").then(m => m.default || m)
  }
]