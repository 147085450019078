<template>
  <v-app>
    <v-main>
      <v-container class="fill-height d-flex flex-column justify-center align-center text-center">
        <v-img
          class="flex-grow-0"
          alt=""
          :width="$vuetify.display.xs ? 180 : 245"
          :height="$vuetify.display.xs ? 80 : 108"
          :src="errorObj.img"
        />

        <div class="text-h5 mt-6 px-4">
          {{ errorObj.title }}
        </div>
        <div
          v-if="errorObj.text"
          class="text-ui-kit-text-secondary mt-2 px-4"
        >
          {{ errorObj.text }}
        </div>

        <div class="mt-8">
          <v-btn
            class="text-primary px-8"
            color="ui-kit-bg-primary"
            variant="flat"
            rounded
            @click="onButtonClick"
          >
            {{ errorObj.buttonText }}
          </v-btn>
        </div>
      </v-container>
    </v-main>

    <RedLineStage />
  </v-app>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import type { PropType } from 'vue'
import { ERRORS } from '~/constants/errors'

const props = defineProps({
  error: { type: Object as PropType<NuxtError>, required: true },
})

const $router = useRouter()

const errorObj = computed(() => {
  const { statusCode: code, message } = props.error

  const errorByCode = ERRORS[code]
  const errorByMessage = ERRORS[message]

  if (code.toString().startsWith('5') || !errorByCode) {
    return ERRORS[500]
  }

  if (!errorByMessage) {
    return errorByCode
  }

  return errorByMessage
})

function onButtonClick() {
  $router.replace({ path: '/' })
}
</script>
