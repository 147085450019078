<template>
  <client-only>
    <div
      v-if="isStage"
      class="red-line-stage"
    >
      STAGE
    </div>
  </client-only>
</template>

<script setup lang="ts">
import { STAGE_ORIGIN_URL } from '~/constants'

const isStage = computed(() => !process.server && window.location.origin === STAGE_ORIGIN_URL)
</script>

<style lang="scss" scoped>
@use 'vuetify/settings';

.red-line-stage {
  background-color: $t-error;
  bottom: 0;
  color: $t-white;
  font-size: $t-font-size-xs;
  font-style: italic;
  font-weight: $t-font-weight-semi-bold;
  left: 0;
  letter-spacing: 2px;
  opacity: .96;
  padding: 8px 16px;
  pointer-events: none;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  width: 100vw;
  z-index: 9999999;

  @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
    bottom: auto;
    font-size: 0;
    padding: 4px;
    top: 0;
  }
}
</style>
