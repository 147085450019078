export const SPONSORS = {
  2022: [
    {
      imgProps: {
        src: '/graphics/sponsors/2022/eto-kosmos-detka.png',
        width: 228,
        height: 62,
        alt: 'Интернет-журнал «Это космос, детка!»',
      },
      title: 'Интернет-журнал «Это&nbsp;космос,&nbsp;детка!»',
      href: 'https://этокосмосдетка.рф/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2022/con-med.png',
        width: 248,
        height: 35,
        alt: 'Ресурс для специалистов в области здравоохранения Con-Med.ru',
      },
      title: 'Ресурс для специалистов в области здравоохранения Con-Med.ru',
      href: 'https://con-med.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2022/narodniyе-novosti.png',
        width: 225,
        height: 47,
        alt: 'Информационный портал «Народные новости»',
      },
      title: 'Информационный портал «Народные&nbsp;новости»',
      href: 'https://nation-news.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2022/rus-news-day.png',
        width: 154,
        height: 122,
        alt: 'Интернет-издание «Реальная Россия – rusnewsday»',
      },
      title: 'Интернет-издание «Реальная&nbsp;Россия&nbsp;–&nbsp;rusnewsday»',
      href: 'https://rusnewsday.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2022/cnii-oiz.png',
        width: 128,
        height: 110,
        alt: 'Центральный научно-исследовательский институт организации и информатизации здравоохранения',
      },
      title: 'Центральный научно&#8209;исследовательский институт организации и&nbsp;информатизации здравоохранения',
      href: 'https://mednet.ru/',
    },
  ],
  2021: [
    {
      imgProps: {
        src: '/graphics/sponsors/2021/medvestnik.png',
        width: 223,
        height: 73,
        alt: 'Портал для работников здравоохранения «Медвестник»',
      },
      title: 'Портал для работников здравоохранения «Медвестник»',
      href: 'https://medvestnik.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/fond-ne-naprasno.png',
        width: 177,
        height: 73,
        alt: 'Фонд медицинских решений «Не напрасно»',
      },
      title: 'Фонд медицинских решений «Не&nbsp;напрасно»',
      href: 'https://nenaprasno.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/cnii-oiz.png',
        width: 128,
        height: 110,
        alt: 'Центральный научно-исследовательский институт организации и информатизации здравоохранения',
      },
      title: 'Центральный научно&#8209;исследовательский институт организации и&nbsp;информатизации здравоохранения',
      href: 'https://mednet.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/rak-izlechim.png',
        width: 248,
        height: 160,
        alt: 'Общественное движение «Рак излечим»',
      },
      title: 'Общественное движение «Рак&nbsp;излечим»',
      href: 'https://www.facebook.com/groups/532563051262730',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/narodnii-novosti.png',
        width: 225,
        height: 47,
        alt: 'Информационный портал «Народные новости»',
      },
      title: 'Информационный портал «Народные&nbsp;новости»',
      href: 'https://nation-news.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/fan.png',
        width: 191,
        height: 43,
        alt: 'Российское информационное агентство «Федеральное агентство новостей»',
      },
      title: 'Российское информационное&nbsp;агентство «Федеральное&nbsp;агентство&nbsp;новостей»',
      href: 'https://riafan.ru/',
    },
    {
      imgProps: {
        src: '/graphics/sponsors/2021/prime-one.png',
        width: 202,
        height: 80,
        alt: 'Глянцевый журнал PRIMEone',
      },
      title: 'Глянцевый журнал <br/> PRIMEone',
      href: 'https://primeone.su/',
    },
  ],
}

export const HOW_IT_WAS = {
  2021: {
    awardText: 'Торжественное награждение Гран-при премии ПроДокторов состоялось в Москве <span class="text-truncate">20 ноября 2021</span>',
    members: [
      {
        title: '«Танцы Минус»',
        subtitle: 'Хедлайнеры',
        avatarSrc: '/graphics/how-it-was/2021/members/Tantsy-Minus.jpg',
      },
      {
        title: 'Сергей Федосов',
        subtitle: 'CEO МедРокет',
        avatarSrc: '/graphics/how-it-was/2021/members/Sergey-Fedosov.jpg',
      },
      {
        title: 'Илья Саньков',
        subtitle: 'Почётный гость <br>«Бионика Диджитал»',
        avatarSrc: '/graphics/how-it-was/2021/members/Ilya-Sankov.jpg',
      },
      {
        title: 'Юлия Ковширина',
        subtitle: 'Почетный гость <br>ФГБУ «ЦНИИОИЗ»',
        avatarSrc: '/graphics/how-it-was/2021/members/Yuliya-Kovshirina.jpg',
      },
    ],
    gallery: {
      video: {
        src: 'https://vk.com/video_ext.php?oid=-217078578&id=456239352&hash=b6c313df54f35bc2',
        start: 753, // Время начала воспроизведения видео (в секундах)
        poster: {
          src: '/graphics/how-it-was/2021/gallery/video.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/video-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
      },
      images: [
        {
          src: '/graphics/how-it-was/2021/gallery/items/1.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/1-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
        {
          src: '/graphics/how-it-was/2021/gallery/items/2.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/2-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
        {
          src: '/graphics/how-it-was/2021/gallery/items/3.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/3-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
        {
          src: '/graphics/how-it-was/2021/gallery/items/4.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/4-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
        {
          src: '/graphics/how-it-was/2021/gallery/items/5.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/5-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
        {
          src: '/graphics/how-it-was/2021/gallery/items/6.jpg',
          srcHq: '/graphics/how-it-was/2021/gallery/items/6-hq.jpg',
          alt: 'Премия ПроДокторов 2021',
        },
      ],
    },
  },
  2022: {
    awardText: 'Торжественное награждение Гран-при премии ПроДокторов состоялось онлайн 19 ноября 2022',
    members: [
      {
        title: 'Вячеслав Карабанов',
        subtitle: 'Ведущий',
        avatarSrc: '/graphics/how-it-was/2022/members/Viacheslav-Karabanov.jpg',
      },
      {
        title: 'Сергей Федосов',
        subtitle: 'CEO МедРокет',
        avatarSrc: '/graphics/how-it-was/2022/members/Sergey-Fedosov.jpg',
      },
    ],
    gallery: {
      video: {
        src: 'https://vk.com/video_ext.php?oid=-217078578&id=456239351&hash=d6772beefc6807e6',
        start: 601, // Время начала воспроизведения видео (в секундах)
        poster: {
          src: '/graphics/how-it-was/2022/gallery/video.jpg',
          srcHq: '/graphics/how-it-was/2022/gallery/video-hq.jpg',
          alt: 'Премия ПроДокторов 2022',
        },
      },
      images: [
        {
          src: '/graphics/how-it-was/2022/gallery/items/1.jpg',
          srcHq: '/graphics/how-it-was/2022/gallery/items/1-hq.jpg',
          alt: 'Премия ПроДокторов 2022',
        },
        {
          src: '/graphics/how-it-was/2022/gallery/items/2.jpg',
          srcHq: '/graphics/how-it-was/2022/gallery/items/2-hq.jpg',
          alt: 'Премия ПроДокторов 2022',
        },
        {
          src: '/graphics/how-it-was/2022/gallery/items/3.jpg',
          srcHq: '/graphics/how-it-was/2022/gallery/items/3-hq.jpg',
          alt: 'Премия ПроДокторов 2022',
        },
      ],
    },
  },
  2023: {
    awardText: `
        Торжественное награждение Гран-при премии ПроДокторов
        состоялось на конференции&nbsp;Дни&nbsp;МедРокет&nbsp;2023
        <br />18&nbsp;ноября 2023&nbsp;года
    `,
    members: [
      {
        title: 'Руслан Исмаилов',
        subtitle: 'Ведущий',
        avatarSrc: '/graphics/how-it-was/2023/members/Ruslam-Ismailov.png',
      },
      {
        title: 'Анна Камышанская',
        subtitle: 'Ведущая',
        avatarSrc: '/graphics/how-it-was/2023/members/Anna-Kamushanskaya.png',
      },
      {
        title: 'Виталий Лучинович',
        subtitle: 'Ведущий',
        avatarSrc: '/graphics/how-it-was/2023/members/Vitaliy-Luchinovich.png',
      },
      {
        title: 'Сергей Федосов',
        subtitle: 'CEO МедРокет',
        avatarSrc: '/graphics/how-it-was/2023/members/Sergey-Fedosov.png',
      },
    ],
    gallery: {
      video: {
        src: 'https://vk.com/video_ext.php?oid=-217078578&id=456239333&hash=9672a3a08b1879a6',
        start: 922, // Время начала воспроизведения видео (в секундах)
        poster: {
          src: '/graphics/how-it-was/2023/gallery/video.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/video-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
      },
      images: [
        {
          src: '/graphics/how-it-was/2023/gallery/items/1.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/1-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/2.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/2-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/3.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/3-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/4.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/4-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/5.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/5-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/6.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/6-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/7.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/7-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/8.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/8-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
        {
          src: '/graphics/how-it-was/2023/gallery/items/9.jpg',
          srcHq: '/graphics/how-it-was/2023/gallery/items/9-hq.jpg',
          alt: 'Премия ПроДокторов 2023',
        },
      ],
    },
  },
}

export const QUESTIONS_AND_ANSWERS = [
  {
    question: 'Как стать участником премии?',
    answers: [
      `Специальная регистрация не нужна. В премии автоматически участвуют:<br>
        &bull; все клиники, у которых есть хотя бы 1 отзыв за год (с 1 ноября по 31 октября)<br>
        &bull; все врачи, у которых есть хотя бы 2 отзыва за год`,
    ],
  },
  {
    question: 'Как определяют победителей среди врачей?',
    answers: [
      `Мы составляем народный рейтинг на основе отзывов за год (с 1 ноября по 31 октября).
        По этому параметру определяем трёх лучших врачей в каждой специальности в каждом регионе.
        Звёздный рейтинг на портале, общее количество отзывов за всё время, стаж — всё это не влияет на победу.`,
      `Для определения победителей в регионе должно быть минимум 10 врачей по специальности, у которых есть хотя бы 2 отзыва за год.
        Если таких врачей меньше, в регионе не будет победителей в этой специальности.`,
    ],
  },
  {
    question: 'Как определяют победителей среди клиник?',
    answers: [
      `Мы&nbsp;составляем рейтинг на&nbsp;основе отзывов о&nbsp;клинике за&nbsp;год (с&nbsp;1&nbsp;ноября по&nbsp;31&nbsp;октября).
        По&nbsp;этому параметру определяем лучших по&nbsp;каждой категории в&nbsp;каждом регионе.<br >
      30&nbsp;лучших частных клиник, 30&nbsp;стоматологических, 30&nbsp;государственных&nbsp;&mdash; в&nbsp;Москве.<br >
      20&nbsp;лучших частных клиник, 20&nbsp;стоматологических, 20&nbsp;государственных&nbsp;&mdash; в&nbsp;Санкт-Петербурге.<br >
      10&nbsp;лучших частных клиник, 10&nbsp;стоматологических, 10&nbsp;государственных&nbsp;&mdash; по&nbsp;регионам.`,

      `Для определения победителей в&nbsp;регионе
      должно быть минимум 10&nbsp;клиник по&nbsp;категории, у&nbsp;которых
      есть хотя&nbsp;бы один отзыв за&nbsp;год.
      Если таких клиник меньше, в&nbsp;регионе не&nbsp;будет победителей в&nbsp;этой категории.`,
    ],
  },
  {
    question: 'Как определяют победителей Гран-при среди врачей?',
    answers: [
      `Мы определяем, у кого из врачей самый высокий народный
        рейтинг за год. Эти врачи становятся лучшими в стране по своей специальности. При этом врач может победить в Гран-при, даже
        если в его регионе было меньше 10 участников по специальности.
        В Гран-при участвуют не все специальности премии — только 55 самых популярных на портале.`,
    ],
  },
  {
    question: 'Как определяют победителей Гран-при среди клиник?',
    answers: [
      `Мы определяем, у каких клиник в каждом округе, Москве и Санкт-Петербурге самый высокий народный
        рейтинг за год. Эти клиники становятся лучшими в своей категории. При этом клиника может победить в Гран-при
        даже в том случае, если в её регионе было меньше 10 клиник в категории.`,
    ],
  },
  {
    question: 'Народный рейтинг – это рейтинг на Портале «ПроДокторов»?',
    answers: [
      `Нет, народный рейтинг в рамках Премии не равен рейтингу на Портале ПроДокторов.
      Народный рейтинг рассчитывается на основании качества и количества отзывов
      только за период с 1 ноября по 31 октября.`,
    ],
  },
  {
    question: 'Что получают призёры?',
    answers: [
      `Врачи-лауреаты в регионах получают грамоты за 1, 2 или 3 место.
      Клиники — грамоты топ-30, топ-20 или топ-10 и большой стикер «Лучшая клиника».
      Мы отправляем их почтой до 1 декабря. Грамоты также можно
      скачать на сайте премии. Кроме этого, у победителей на портале появляется медаль, которую видят
      все посетители сайта.`,
      `Призёры Гран-при получают дипломы, победители — дипломы и статуэтки. Награды Гран-при вручаются
        врачам и представителям клиник на церемонии награждения.`,
    ],
  },
  {
    question: 'Почему в нашем регионе нет победителей по некоторым специальностям?',
    answers: [
      `Значит, в регионе нет 10 врачей этой специальности, которым оставили хотя бы два отзыва за год (с 1 ноября
        по 31 октября). Или 10 клиник этой категории, которым оставили хотя бы один отзыв. Например, в регионе
        11 гематологов, но у трёх из них нет отзывов за год. В этом случае гематологи не участвуют в первом этапе
        премии.`,
    ],
  },
  {
    question: 'Если у врача несколько специальностей, какая учитывается в премии?',
    answers: [
      'Врач участвует в&nbsp;премии только по&nbsp;главной специальности. В&nbsp;премии нельзя победить сразу в&nbsp;нескольких номинациях.',
    ],
  },
  {
    question: 'Почему победил врач, у которого меньше положительных отзывов, чем у других?',
    answers: [
      `Мы берём в расчёт только отзывы за год (с 1 ноября по 31 октября).
      Если врач победил, значит у него самый высокий народный рейтинг в рамках Премии по отзывам за год.`,
    ],
  },
  {
    question: 'Если в течение года врач сменил регион, будут ли учитываться отзывы пациентов прежнего региона?',
    answers: [
      'Учитываются все отзывы за&nbsp;год, независимо от&nbsp;того, в&nbsp;каком регионе они оставлены.',
    ],
  },
  {
    question: 'Влияет ли победа в премии на рейтинг?',
    answers: [
      `Нет, победа в&nbsp;премии не&nbsp;прибавляет баллы в&nbsp;рейтинге.
      Но&nbsp;на&nbsp;страницах победителей на&nbsp;портале появляется медаль,
      которую видят все посетители сайта.`,
    ],
  },
]

export const AWARD_ROUTE_NAMES = {
  prefix: 'awards',
  main: 'awards-year',
  town: 'awards-year-city',
  lpu: 'awards-year-city-lpus',
  doctor: 'awards-year-city-doctors',
}

export const GRAND_PRIX_ROUTE_NAMES = {
  prefix: 'grand-prix',
  main: 'grand-prix-year',
  lpu: 'grand-prix-year-lpus',
  doctor: 'grand-prix-year-doctors',
}

export const DOCS_CURRENT_YEAR = 2024
