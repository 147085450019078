// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxt) => {
  const { sentryDSN, buildVersion } = useRuntimeConfig().public
  // https://docs.sentry.io/platforms/javascript/guides/vue/
  Sentry.init({
    app: nuxt.vueApp,
    release: buildVersion || '',
    // При undefiend даёт доступ до sentry и при это не отправляет event'ы (полезно для dev режима).
    dsn: sentryDSN || undefined,
    integrations: [
      Sentry.browserTracingIntegration({ router: useRouter() }),
      Sentry.replayIntegration(),
    ],
    normalizeDepth: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  })
})

