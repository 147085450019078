interface IErrorMessage {
  code: number
  title: string
  text?: string
  img: string
  buttonText: string
  href?: string
}

export const ERRORS: Record<string | number, IErrorMessage> = {
  404: {
    code: 404,
    title: 'На этой странице ничего нет',
    text: 'Возможно, материал удалён или в ссылке ошибка',
    img: '/graphics/404.svg',
    buttonText: 'На главную',
  },
  500: {
    code: 500,
    title: 'У нас что-то сломалось',
    text: 'Исправим в ближайшее время',
    img: '/graphics/500.svg',
    buttonText: 'На главную',
  },
}
