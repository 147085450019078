import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { merge } from 'lodash-es'

export const baseDomain = process.server ? process.env.AWARD_URL : ''
const baseURL = `${baseDomain}/api`

export interface IAxiosClient {
  get: AxiosInstance['get']
  post: AxiosInstance['post']

  $get<T = any>(url: string, config?: AxiosRequestConfig, year?: number): Promise<T>
  $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
}

const $axios = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const axiosClient: IAxiosClient = {
  get: $axios.get,
  $get: async (url, config, year) => (
    await $axios.get(url, year
      ? merge(config, {
        params: {
          year,
        },
      })
      : config)
  )?.data,
  post: $axios.post,
  $post: async (url, data) => (await $axios.get(url, data))?.data,
}
