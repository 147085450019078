import type { MeGetResponse } from '~/api/me'
import MeApi from '~/api/me'
import type { ISimpleRegion, IDoctorGroup } from '~/types'
import type { TIndexResponse } from '~/features/Home/types'
import type { ILpuDistrict, ILpuGroup } from '~/features/lpus/types'

type AwardState = 'year-start' | 'announcement' | 'year-end'
type GrandPrixState = 'year-start' | 'announcement' | 'year-end'

export const useMainStore = defineStore('main', () => {
  const indexPage = ref<null | TIndexResponse>(null)
  const awardYears = ref<MeGetResponse['award_years']>([])
  const siteSettings = ref<MeGetResponse['site_settings']>({})
  const grandPrixYears = ref<MeGetResponse['grand_prix_years']>([])
  const isFirstLoad = ref(true)
  const region = ref<ISimpleRegion | null>(null)
  const lpuGroups = ref<Record<string, ILpuGroup[]>>({})
  const doctorGroups = ref<Record<string, IDoctorGroup[]>>({})
  const feature_flags = ref<MeGetResponse['feature_flags']>({})
  const lpuDistricts = ref<Record<string, ILpuDistrict[]>>({})

  const awardState = computed<AwardState>(() => {
    if (
      siteSettings.value.enable_announce_current_year_award &&
      !siteSettings.value.enable_show_award_result
    ) {
      return 'announcement'
    }

    const currentYear = new Date().getFullYear()
    const lastAwardYear = awardYears.value[0]

    if (currentYear > lastAwardYear) {
      return 'year-start'
    }

    return 'year-end'
  })

  const grandPrixState = computed<GrandPrixState>(() => {
    if (
      siteSettings.value.enable_announce_current_year_award &&
      !siteSettings.value.enable_show_grand_prix_result
    ) {
      return 'announcement'
    }

    const currentYear = new Date().getFullYear()
    const lastGrandPrixYear = grandPrixYears.value[0]

    if (currentYear > lastGrandPrixYear) {
      return 'year-start'
    }

    return 'year-end'
  })

  const awardYear = computed(() => {
    const currentYear = new Date().getFullYear()
    const previousYear = currentYear - 1

    return awardState.value === 'year-start' ? previousYear : currentYear
  })

  function isFeatureFlagEnabled(featureFlag: string) {
    return Boolean(feature_flags.value[featureFlag])
  }

  async function fetch() {
    const api = new MeApi()

    if (!isFirstLoad.value) {
      return
    }

    try {
      const data = await api.get()
      isFirstLoad.value = false
      feature_flags.value = data.feature_flags
      siteSettings.value = data.site_settings
      awardYears.value = data.award_years.reverse()
      grandPrixYears.value = data.grand_prix_years.reverse()
    } catch (error) {
      showError(error)
    }
  }

  return {
    indexPage,
    awardYears,
    grandPrixYears,
    isFirstLoad,
    region,
    lpuGroups,
    doctorGroups,
    feature_flags,
    siteSettings,
    lpuDistricts,

    awardState,
    grandPrixState,
    awardYear,
    isFeatureFlagEnabled,
    fetch,
  }
})
