import revive_payload_client_4sVQNw7RlN from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/app/pd-awards-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/app/pd-awards-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_plugin_u5jU6XzrZj from "/home/app/pd-awards-frontend/modules/vuetify/runtime/plugin/vuetify.plugin.ts";
import plugin_t2GMTTFnMT from "/home/app/pd-awards-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/app/pd-awards-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_client_2rh1pDAIty from "/home/app/pd-awards-frontend/plugins/01.sentry.client.ts";
import yandex_metrika_client_MiNHrQhx1f from "/home/app/pd-awards-frontend/plugins/yandex-metrika.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_plugin_u5jU6XzrZj,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  _01_sentry_client_2rh1pDAIty,
  yandex_metrika_client_MiNHrQhx1f
]